import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import "../App.css";
import Navbar from "../navbar/navbar";

const Layout = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        position: "relative",
        backgroundImage: 'url("971.jpg")',
        backgroundSize: "cover",
        height: "100vh",
        zIndex: 0, // Ensure the background is behind other content
      }}
      >
      <Navbar />
      <Container >
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          {/* <div style={glassyTextStyle}> */}
          <Typography
            variant="h3"
            gutterBottom
            style={{ color: "white", fontWeight: "bold" }}
          >
            Welcome to
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            style={{ color: "#42a9f5", fontWeight: "bold" }}
          >
            FEEL & CODE
          </Typography>

          <Typography
            variant="h6"
            color="textSecondary"
            paragraph
            style={{ color: "white", fontWeight: "bold" }}
          >
            Welcome to a world of innovative IT solutions that empower your
            business.
          </Typography>
          {/* </div> */}

          <div style={{ marginTop: "50px" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                color: "white",
                fontWeight: "bold",
                zIndex: 3,
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(66, 135, 245, 0.1)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust hover effect if desired
                },
              }}
            >
              Explore Our Services
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Layout;
