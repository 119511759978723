// App.js
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import Layout from './home/home';
import Next from './home/next';
import Contact from "./home/contact";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}/>
        <Route path="blog" element={<Next />} />
        <Route path="contact" element={<Contact />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
