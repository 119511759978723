import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const Navbar = ({ customStyles }) => {
  return (
    <AppBar
    style={customStyles}
      position="fixed"
      color="primary"
      elevation={4}
      sx={{
        '@media (max-width: 600px)': { position: 'relative' },
        backdropFilter: 'blur(10px)', // Adjust the blur value as needed
        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Adjust the color and opacity as needed
      }}
    >
      <Toolbar sx={{ paddingRight: '20px', paddingLeft: '20px' }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '1.5rem', fontWeight: 'bold' }}>
          FEELANDCODE
        </Typography>

        <Button component={Link} to="/" color="inherit" sx={{ color: 'white', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' } }}>
          Home
        </Button>

        <Button component={Link} to="/blog" color="inherit" sx={{ color: 'white', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' } }}>
          Blog
        </Button>

        <Button component={Link} to="/contact" color="inherit" sx={{ color: 'white', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' } }}>
          Contact
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
