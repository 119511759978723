// HomePage.js
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Navbar from "../navbar/navbar";

const Contact = () => {
  const navbarStyles = {
    backgroundColor: "rgba(23, 18, 115, 0.8)",
  };
  return (
    <div
      style={{
        zIndex: "2",
        backgroundImage: 'url("productCurvyLines.png")',
        backgroundSize: "contain",
        height: "100vh",
        position: "relative", // Make sure the position is relative
      }}
    >
      <Navbar customStyles={navbarStyles} />
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* Your Company Logo or Name */}
          </Typography>
          {/* You can add additional navigation links here if needed */}
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container>
        <div style={{ textAlign: "center", marginTop: "80px" }}>
          <h1>Goodbye</h1>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
